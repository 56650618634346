import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';
import storage from '../../utils/storage';

const fadeIn = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  },
  100% {
    transform: scale('100');
    opacity: 1;
  }
`;

const EmmiContainer = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,400;0,500;1,400;1,500&family=Khula:wght@600;700&family=Source+Sans+Pro&display=swap');
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  width: 100%;
  padding: 20px 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 801px) {
    flex-direction: column;
    padding: 10px 10px 20px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  max-width: 400px;
  border-right: 4px solid #000;
  padding-right: 40px;
  img {
    width: 100%;
    margin-bottom: 0;
  }

  @media (max-width: 801px) {
    border-right: none;
    padding-right: 0;
    max-width: 200px;
    margin: 0 auto;
  }
`;

const TextContainer = styled.div`
  width: 100%;
  max-width: 400px;
  p {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 20px;
    // font-style: italic;
    font-family: 'Source Sans Pro', sans-serif;
    @media (max-width: 801px) {
      font-size: 15px;
      text-align: center;
      margin-bottom: 1rem;
    }
  }
`;

// const DividerDiv = styled.div`
//   border: 3px solid #000;
//   min-height: 100px;
// `;

const LinkButtonContainer = styled.div`
  width: 100%;
  max-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3488ed;
  color: #fff;
  padding: 10px 0;
  border-radius: 5px;
  border: solid 1px #000;
  font-weight: 700;
  animation: ${fadeIn} 0.9s;
  &:hover {
    cursor: pointer;
  }
  p {
    font-family: 'Khula', sans-serif;
    margin-bottom: 0;
    font-size: 34px;
    margin-top: 4px;
    @media (max-width: 801px) {
      font-size: 24px;
    }
  }
  @media (max-width: 801px) {
    font-size: 24px;
    max-width: 170px;
  }
`;

const MidContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #3488ed;
  height: 200px;
  color: #fff;
  h1 {
    font-weight: 700;
    font-size: 60px;
    font-style: italic;
    font-family: 'Khula', sans-serif;
    @media (max-width: 801px) {
      font-size: 24px;
    }
  }
`;

const MappedContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 960px;
  margin: 0 auto;
  p {
    margin-bottom: 0;
    border-right: 4px solid #fff;
    margin-left: 10px;
    padding-right: 10px;
    font-family: 'Khula', sans-serif;
    line-height: 1.2;
    @media (max-width: 801px) {
      border-right: none;
      margin-left: 0;
      padding-right: 0;
      margin-bottom: 0.75rem;
      font-size: 15px;
    }
  }
  @media (max-width: 801px) {
    flex-direction: column;
  }
`;

const LowerContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  width: 100%;
  @media (max-width: 801px) {
    flex-direction: column;
    padding: 0 0 20px;
  }
`;

const LowerImageContainer = styled.div`
  width: 48%;
  max-width: 400px;
  img {
    width: 100%;
    margin-bottom: 0;
  }
  .greyText {
    font-size: 15px;
    color: #b9b9b9;
    margin-bottom: 0;
    font-family: 'Source Sans Pro', sans-serif;
  }
  @media (max-width: 801px) {
    width: 100%;
    padding: 0 10px 20px;
  }
`;

const LowerContainerText = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
  font-style: italic;
  .boldText {
    font-family: 'Bitter', serif;
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 10px;
    @media (max-width: 801px) {
      font-size: 15px;
      text-align: center;
    }
  }
  a {
    font-family: 'Source Sans Pro', sans-serif;
    color: #3488ed;
    font-size: 15px;
    font-style: initial;
    @media (max-width: 801px) {
      text-align: center;
    }
  }
  @media (max-width: 801px) {
    width: 100%;
    padding: 0 10px;
  }
`;

const EmmiThankyou = () => {
  const [affId, setAffId] = useState('');
  const [reqId, setReqId] = useState('');
  const [redirectLink, setRedirectLink] = useState('');

  useEffect(() => {
    const formData = storage('session', 'get', 'formData');

    if (formData) {
      const parsedFormData = JSON.parse(formData);
      const { request_id, affiliate_id } = parsedFormData;
      setReqId(request_id);
      setAffId(affiliate_id);
    }
  }, []);

  useEffect(() => {
    if (affId && reqId) {
      setRedirectLink(
        `https://www.morty.com/l/ratemarketplace?campaign=${affId}&utm_source=ratemarketplace&utm_medium=paid&utm_campaign=&source_id=${reqId}EditDel`
      );
    } else {
      setRedirectLink(
        `https://www.morty.com/l/ratemarketplace?campaign=#affid#&utm_source=ratemarketplace&utm_medium=paid&utm_campaign=&source_id=#reqid#EditDel`
      );
    }
  }, [affId, reqId]);

  const handleRedirect = url => {
    window.open(url, '_blank');
  };

  const midContentText = [
    { content: `Get a pre-approval letter in minutes` },
    { content: `Explore customized loan options in real time` },
    { content: `Streamlined online platform, from lock to close` },
    { content: `Support from Morty’s mortgage experts` },
  ];

  return (
    <EmmiContainer>
      <HeaderContainer>
        <ImageContainer>
          <img
            src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/mortyLogo.png"
            alt="MortyLogo"
          />
        </ImageContainer>
        {/* <DividerDiv /> */}
        <TextContainer>
          <p>
            We help you find the right loan, and guide you from pre-approval to
            closing without the pain and hassle that typically comes with buying
            a home.
          </p>
        </TextContainer>
        {redirectLink && (
          <LinkButtonContainer onClick={() => handleRedirect(redirectLink)}>
            <p>Click Here</p>
          </LinkButtonContainer>
        )}
      </HeaderContainer>
      <MidContainer>
        <h1>Get Your Personalized Quote</h1>
        <MappedContent>
          {midContentText.map((el, key) => (
            <p key={key}>{el.content}</p>
          ))}
        </MappedContent>
      </MidContainer>
      <LowerContainer>
        <LowerImageContainer>
          <img
            src="https://s3-us-west-1.amazonaws.com/offers.printfingertech.net/images/MortyHouseTy.png"
            alt="house"
          />
          <p className="greyText">Morty, inc. NMLS #1429243</p>
        </LowerImageContainer>
        <LowerContainerText>
          <p className="boldText">
            I never would have been able to navigate purchasing my home without
            Morty. The entire team was super responsive and very helpful. I
            would absolutely work with the Morty team again.{' '}
          </p>
          <a
            href="https://www.trustpilot.com/review/morty.com"
            target="_blank"
            rel="noreferrer"
          >
            https://www.trustpilot.com/review/morty.com
          </a>
        </LowerContainerText>
      </LowerContainer>
    </EmmiContainer>
  );
};

const fontLink =
  'https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap';

export default withThankyouWrapper(EmmiThankyou, fontLink);
